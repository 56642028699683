export const navigationQuery = gql`
  query getNavigation($handle: String!, $site: String!) {
    nav(handle: $handle) {
      title
      handle
      max_depth
      expects_root

      tree(site: $site) {
        depth

        page {
          id
          url
          title
          permalink

          ... on NavEntryPage_Main_Pages_Page {
            in_bottom_menu
            show_vacancies_count

            image {
              id
              md: responsiveAsset(width: 600, height: 1200, format: "webp") { url, alt, width, height }
              lg: responsiveAsset(width: 1200, height: 1200, format: "webp") { url, alt, width, height }
            }
          }
        }
      }
    }
  }
`;
