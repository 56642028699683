<template>
  <div
    :class="`
      fixed inset-0 -z-[1] h-screen w-screen transition-colors duration-1000
      ${canvasState.backgroundColor}
    `"
  >
    <canvas
      ref="canvas"
      class="fixed inset-0 -z-[1] h-screen w-screen mix-blend-difference"
      width="500"
      height="500"
      data-fullscreen="true"
    />
  </div>
</template>

<script setup lang="ts">
import shader from '../../lib/shaders/default';

const { $glsl } = useNuxtApp();
const { canvasState } = useCanvas();

const canvas = ref();
const sandbox = ref();

function formatVec3(vec3?: string) {
  if (!vec3) return '';

  return vec3.split(',').map((value: string) => {
    return parseFloat(value.trim());
  });
}

watch(() => canvasState.vec3, (newVec3) => {
  if (!sandbox.value) return;

  sandbox.value.setUniform('u_color', ...formatVec3(newVec3));
});

onMounted(() => {
  sandbox.value = new $glsl(canvas.value);

  sandbox.value.load(shader);
  sandbox.value.setUniform('u_color', ...formatVec3(canvasState.vec3));
  sandbox.value.realToCSSPixels = 1;

  document.addEventListener('visibilitychange', (event: Event) => {
    const target = event.target as Document;

    if (typeof target?.hidden !== 'undefined') {
      sandbox.value[target?.hidden ? 'pause' : 'play']();
    }
  }, false);
});
</script>
