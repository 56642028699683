const mainNavigationState = reactive<{
  id: string;
  isOpen: boolean;
}>({
  id: 'main-navigation',
  isOpen: false,
});

export const useMainNavigation = () => {
  function toggleMainNavigation(state?: boolean) {
    if (typeof state !== 'undefined') {
      mainNavigationState.isOpen = state;

      return;
    }

    mainNavigationState.isOpen = !mainNavigationState.isOpen;
  }

  return {
    mainNavigationState,
    toggleMainNavigation,
  };
};
