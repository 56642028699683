<template>
  <form
    action="https://m10.mailplus.nl/genericservice/code/servlet/Redirect"
    method="POST"
    class="flex items-center gap-4 lg:gap-8"
    enctype="application/x-www-form-urlencoded"
    @submit="submit"
  >
    <div style="display: none;">
      <input ref="honeypot" type="text" name="fax" autocomplete="off" tabindex="-1">
    </div>

    <input type="hidden" name="userId" value="503100506">
    <input type="hidden" name="formEncId" value="QdE8iPgbx83bEXyzkPEB">
    <input type="hidden" name="pagePosition" value="1">
    <input type="hidden" name="viewMode" value="STATICINTEGRATION">
    <input type="hidden" name="redir" value="formAdmin2">

    <input
      type="email"
      class="form-input px-0 h-14 w-full text-lg text-white bg-transparent border-0 border-b border-white/25 placeholder-white/50 focus:border-blue-light focus:ring-0"
      name="field1155"
      :placeholder="$statamic.trans('footer_newsletter_input_placeholder')"
      required
    >

    <button
      type="submit"
      class="btn btn--quaternary btn--rounded size-14 shrink-0"
      :aria-label="$statamic.trans('a11y_submit_newsletter_form')"
    >
      <Icon icon="svgo-arrow-right" class="h-5 w-5" />
    </button>
  </form>
</template>

<script setup lang="ts">
const honeypot = ref();

const submit = async (event: Event) => {
  if (honeypot.value?.value) {
    event.preventDefault();
    return;
  }
};
</script>
