import { navigationQuery } from '~/statamic/queries/navigation';
import type { Navigation } from '~/statamic/types/navigation';

type NavigationOptions = {
  site?: string;
  handle: string;
};

export const useStatamicNavigation = async (options: NavigationOptions): Promise<Navigation> => {
  const { $statamic } = useNuxtApp();

  if (!options.site) {
    options.site = $statamic.site.handle;
  }

  const { data } = await useAsyncQuery<{nav: Navigation}>(navigationQuery, options);
  const navigation = data?.value?.nav;

  if (!navigation) {
    throw new Error('Missing navigation from Statamic.');
  }

  return navigation;
};
