<template>
  <Header />
  <Navigation />

  <div id="smooth-wrapper">
    <div id="smooth-content" class="will-change-transform">
      <main id="main" class="min-h-screen overflow-hidden" tabindex="-1">
        <slot />
      </main>

      <Footer />
    </div>
  </div>

  <BackgroundCanvas />
</template>

<script setup lang="ts">
const { $scrollSmoother } = useNuxtApp();
const { overlayState } = useOverlay();
const { mainNavigationState } = useMainNavigation();

let body: HTMLBodyElement | null;

useHead({
  bodyAttrs: {
    class: 'font-sans font-light antialiased bg-[#0B0E14]',
  },
});

useNuxtApp().hook('page:transition:finish', () => {
  useScrollTrigger.refresh();
});

useNuxtApp().hook('page:loading:end', () => {
  useScrollTrigger.refresh();
});

watch(() => overlayState.isOpen, (isOpen) => {
  if (body) {
    body.classList[isOpen ? 'add' : 'remove']('overflow-hidden');
  }

  $scrollSmoother.paused(isOpen);
});

watch(() => mainNavigationState.isOpen, (isOpen) => {
  if (body) {
    body.classList[isOpen ? 'add' : 'remove']('overflow-hidden');
  }

  $scrollSmoother.paused(isOpen);
});

onMounted(() => {
  body = document.querySelector('body');
});
</script>
