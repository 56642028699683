<template>
  <nav
    :id="mainNavigationState.id"
    ref="mainNavigationRef"
    class="fixed inset-0 z-50 pt-4 px-4 h-screen w-screen max-h-svh opacity-0 invisible translate-y-full will-change-transform md:pt-8 md:px-8 lg:pt-12 lg:px-12"
    :aria-expanded="mainNavigationState.isOpen ? 'true' : 'false'"
  >
    <div class="fixed inset-0 bg-blue-light opacity-0 invisible" data-gsap-target="background" />

    <div class="relative overflow-hidden flex h-full w-full bg-white rounded-t-2xl sm:grid sm:grid-cols-[18.75rem,1fr] lg:rounded-t-4xl xl:grid-cols-[32.75rem,1fr]">
      <button
        type="button"
        class="btn btn--secondary btn--rounded absolute top-4 right-4 h-10 w-10 after:bg-blue-light hover:border-blue-light md:h-12 md:w-12 lg:top-11 lg:right-11"
        @click="() => toggleMainNavigation()"
        :aria-label="$statamic.trans('a11y_toggle_navigation')"
        :aria-controls="mainNavigationState.id"
        :aria-expanded="mainNavigationState.isOpen ? 'true' : 'false'"
      >
        <MenuIcon :is-open="mainNavigationState.isOpen" />
      </button>

      <div
        v-if="allMenuItems?.length"
        class="relative overflow-hidden hidden sm:block"
      >
        <img
          v-for="item in allMenuItems"
          :key="item.page.id"
          :src="item.page.image?.md.url"
          :alt="item.page.image?.md.alt"
          :class="`
            absolute inset-0 h-full w-full object-cover opacity-0 transition-opacity duration-300
            ${currentMenuItem === item.page.id ? 'opacity-100' : ''}
          `"
          :width="item.page.image?.md.width"
          :height="item.page.image?.md.height"
          :srcset="`
            ${item.page.image?.lg.url} ${item.page.image?.lg.width}w,
            ${item.page.image?.md.url} ${item.page.image?.md.width}w
          `"
          sizes="
            (min-width: 1280px) 524px,
            (min-width: 640px) 300px
          "
          loading="lazy"
          draggable="false"
        >
      </div>

      <div
        v-if="menuItems?.length"
        class="flex flex-col gap-1 grow pt-14 px-4 pb-4 overflow-y-scroll sm:px-8 sm:pb-6 lg:gap-8 lg:pt-20 lg:px-16 lg:pb-14"
      >
        <div
          v-for="item in menuItems"
          :key="item.page.id"
          class="opacity-0 translate-y-10 translate-x-10 will-change-transform"
          data-gsap-target="menuItem"
        >
          <a
            :href="item.page.url"
            :class="`
              inline-block text-2xl text-purple-dark font-serif font-bold origin-left will-change-transform transition duration-300 hover:scale-125 hover:translate-x-6 lg:text-[3.5rem] lg:leading-10
              ${currentMenuItemHover === item.page.id || !currentMenuItemHover ? 'opacity-100' : 'opacity-50'}
            `"
            @mouseenter="() => onMenuItemMouseEnter(item.page.id)"
            @mouseleave="() => onMenuItemMouseLeave()"
          >
            {{ item.page.title }}
          </a>
        </div>

        <div
          v-if="subMenuItems?.length"
          class="flex flex-col gap-1 mt-auto pt-4 border-t border-purple-dark lg:pt-7"
        >
          <div
            v-for="item in subMenuItems"
            :key="item.page.id"
            class="opacity-0 translate-y-10 translate-x-10 will-change-transform"
            data-gsap-target="subMenuItem"
          >
            <a
              :href="item.page.url"
              :class="`
                inline-flex items-center text-xl text-purple-dark font-serif font-medium origin-left will-change-transform transition duration-300 hover:translate-x-6 lg:text-2xl
                ${currentMenuItemHover === item.page.id || !currentMenuItemHover ? 'opacity-100' : 'opacity-50'}
              `"
              @mouseenter="() => onMenuItemMouseEnter(item.page.id)"
              @mouseleave="() => onMenuItemMouseLeave()"
            >
              {{ item.page.title }}

              <div
                v-if="item.page.show_vacancies_count"
                class="inline-flex items-center justify-center -mb-1 pb-px ml-2 h-5 w-5 font-sans font-light text-sm text-white bg-orange rounded-full"
              >
                {{ vacancies.length }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
const route = useRoute();
const { data: vacancies } = await useStatamicVacancies();
const { tree: allMenuItems } = await useStatamicNavigation({ handle: 'main' });
const { mainNavigationState, toggleMainNavigation } = useMainNavigation();

let ctx: gsap.Context | null;
let timeline: GSAPTimeline;
const mainNavigationRef = ref();

let onMenuItemMouseEnter: Function;
let onMenuItemMouseLeave: Function;

const menuItems = allMenuItems.filter((menuItem) => !menuItem.page.in_bottom_menu);
const subMenuItems = allMenuItems.filter((menuItem) => menuItem.page.in_bottom_menu);

const currentMenuItem = ref<string>(menuItems[0].page.id);
const currentMenuItemHover = ref<string | null>(null);

watch(() => route.path, () => {
  toggleMainNavigation(false);
});

watch(() => mainNavigationState.isOpen, (isOpen) => {
  if (isOpen) {
    timeline.timeScale(1).play();

    useGtm().trackEvent({
      event: 'main_navigation_opened',
      navigation_name: 'main',
    });
  } else {
    timeline.timeScale(5).reverse();
  }
});

onMounted(() => {
  onMenuItemMouseEnter = (id: string) => {
    currentMenuItem.value = id;
    currentMenuItemHover.value = id;
  };

  onMenuItemMouseLeave = () => {
    currentMenuItem.value = menuItems[0].page.id;
    currentMenuItemHover.value = null;
  };

  ctx = useGsap.context((self) => {
    if (typeof self.selector !== 'function') return;

    const gsapBackground = self.selector('[data-gsap-target="background"]');
    const gsapMenuItems = self.selector('[data-gsap-target="menuItem"]');
    const gsapSubMenuItems = self.selector('[data-gsap-target="subMenuItem"]');

    timeline = useGsap.timeline({
      paused: true,
    });

    timeline
      .to(mainNavigationRef.value, {
        y: 0,
        autoAlpha: 1,
      })
      .to(gsapBackground, {
        autoAlpha: 1,
      });

    if (gsapMenuItems?.length) {
      timeline.to(gsapMenuItems, {
        y: 0,
        x: 0,
        stagger: 0.1,
        autoAlpha: 1,
      }, '-=0.5');
    }

    if (gsapSubMenuItems?.length) {
      timeline.to(gsapSubMenuItems, {
        y: 0,
        x: 0,
        stagger: 0.1,
        autoAlpha: 1,
      }, '-=0.5');
    }
  }, mainNavigationRef.value);
});

onUnmounted(() => {
  ctx?.revert();
});
</script>
